import React, { useState } from "react";

import * as styles from './accordion.module.scss';
// import ArrowHead from '../../../../static/icons/icons-arrow-head.svg';
// import ArrowHead from '../../../static/icons/icons-arrow-head.svg';
const Accordion = ({
    children,
    arrowColor,
    isDesktop // By default, the accordion is a mobile first function, but can also be used on desktop
}: {
    children: any
    arrowColor?: string,
    isDesktop?: boolean
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const onAccordionButtonClick = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={`${styles.accordion} ${arrowColor ? styles.accordionBlack : ''} ${isOpen ? styles.accordionIsOpen : styles.accordionIsClose} ${isDesktop ? styles.accordionIsDesktop : ''}`}>
            <button className={`${styles.accordionButton} ${arrowColor ? `styles.accordionButtonBlack` : ''} ${isDesktop ? styles.accordionButtonIsDesktop : ''}`} onClick={onAccordionButtonClick}>
                <div className={`styles.accordionbutton__title`}>{children[0]}</div>
                <div className={`${styles.accordionButton__arrow} ${arrowColor ? styles.accordionButton__arrowBlack : ''} ${isDesktop ? styles.accordionButton__arrowIsDesktop : ''}`}>
                    <svg width="20" height="15" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.65575 0.896124L4.99975 5.55212L0.343751 0.896123L1.01575 0.224122L4.99975 4.20812L8.98375 0.224123L9.65575 0.896124Z" fill="white" />
                    </svg>
                </div>
            </button>
            <div className={`${styles.accordionOptions} ${isDesktop ? styles.accordionOptionsIsDesktop : ''}`}>
                {children[1]}
            </div>
        </div>
    )
}

export default Accordion