// extracted by mini-css-extract-plugin
export var articlePage = "article-product-slider-module--article-page--46e6f";
export var bar = "article-product-slider-module--bar--eee27";
export var col = "article-product-slider-module--col--12341";
export var h3 = "article-product-slider-module--h3--80716";
export var helpCenter = "article-product-slider-module--help-center--21640";
export var paddingAdded = "article-product-slider-module--paddingAdded--cb2b1";
export var productCard = "article-product-slider-module--product-card--ae61f";
export var reccomendedProducts = "article-product-slider-module--reccomendedProducts--12b45";
export var slickContainer = "article-product-slider-module--slick-container--f2552";
export var slickDots = "article-product-slider-module--slick-dots--95ec7";
export var slickSlider = "article-product-slider-module--slick-slider--90404";