import React from "react";
import { RichText } from "../../Layout/RichText/RichText";
import Img from 'gatsby-image';
import * as styles from './faq-title.module.scss';
import { useSiteQuery, useDictionaryQuery } from "../../../hooks";
const FAQTitle = ({ data, noticePage }) => {
    const {content, heading, subHeading} = data
    const { articlesGlobalIcon } = useSiteQuery()
    const { contactUs } = useDictionaryQuery() 

    return (
        <div>
            <div className={noticePage ? 'notice-container' : styles.faqContainer}>
                {noticePage ? <h2 className={`${ styles.noticeHeading } underline_border`}>{contactUs ? contactUs : heading}</h2> : 
                    <h2 className={`${styles.contactUs} yellow_border`}>{contactUs ? contactUs : heading}</h2>
                }
                
                {/* {heading && <h2 className={styles.noticeHeading}>{heading}</h2>} */}
                {subHeading && <h2 className={styles.noticeSubHeading}>{subHeading}</h2>}
                {!noticePage && articlesGlobalIcon.fluid && <div className={styles.contactIcon}>
                    <Img fluid={articlesGlobalIcon?.fluid} alt={articlesGlobalIcon?.title} />
                </div>}
                <div className={noticePage ? styles.noticeContent : ""}>
                    {
                        content &&
                        <RichText content={content} />
                    }
                </div>
            </div>
        </div>
    )
}

export default FAQTitle;