import React from "react";
import * as styles from './faq-component.module.scss';
import FAQContent from "./FaqContent/FAQContent";
import Accordion from "../Shared/Accordion/Accordion";
const FAQComponent = ({
    title,
    section = []
}: {
    title: String,
    section: any
}) => {
    return (
        <div>
            <div className={styles.faqPage}>
            {title &&
                <h3 className="yellow_border">{title}</h3>
            }
            </div>
            <div className={styles.faqAccordion}>
                {section?.map(({ heading, content }, index) => {
                    return (
                        <FAQContent heading={heading} content={content} key={index} />
                    )
                })}
            </div>
        </div>
    )
}

export default FAQComponent