import React from "react";
import * as styles from './article-product-slider.module.scss';
import { useUrl, useLocalPath } from "../../hooks";
import { Link } from "gatsby";
// import Slider from "react-slick"
import Img from 'gatsby-image';
import { useLocale } from "../../hooks";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper";

const ArticleProductSlider = ({
    productArray,
    title,
    displayButton,
    extraClass
}: {
    productArray: Array<{}>,
    title?: String,
    displayButton?: Boolean,
    extraClass?: any
}) => {
    const option = {
        spaceBetween: 20,
        loop: productArray?.length > 3 ? true : false,
        grabCursor: true,
        keyboard: {
            enabled: true
        },
        pagination: {
            dynamicBullets: true,
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
                // navigation: false
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 1.5,
                spaceBetween: 10,
                navigation: true
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 30
            }
        },
        navigation: true,
        modules: [Pagination, Navigation],
        slidesPerView: 3,
    }
    const locale = useLocale()
    return (
        <div className={`${styles[extraClass]}`}>
            {title && <h3 className={styles.h3}>{title}</h3>}
            {/* <div className={`${styles.grid_col_3}`}> */}
            <div className={styles[extraClass]}>
                <Swiper {...option}>
                    {productArray?.map((data: any, index: number) => {
                        const { title, slug, thumbnailImage } = data;
                        const cta = data.cta
                        return (
                            <SwiperSlide key={index}>
                                <div className={`${styles.col} ${styles.productCard}`}>
                                    <Link to={useLocalPath(`/${slug}`)} >
                                       <Img
                                            className={` ${locale === 'en-US' ? styles.paddingAdded : ''}`}
                                            fluid={thumbnailImage.fluid}
                                            alt={thumbnailImage.title}
                                        ></Img>
                                        <p>{title}</p>
                                    </Link>
                                    {displayButton && locale !== 'en-AU' &&  locale  !== 'en-US' &&
                                        <Link to={useLocalPath(`/${slug}`)}>
                                            <button>{cta && cta[0] && cta[0].title ? cta[0].title : 'Shop Now'}</button>
                                        </Link>
                                    }

                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

            </div>
            {/* </div> */}
        </div>
    )
}

export default ArticleProductSlider