import React from "react"
import { RichText } from "../../Layout/RichText/RichText"
import Img from "gatsby-image"
import * as styles from "./sectionIconDescription.module.scss"

const SectionIconDescription = ({title,description,buttonText,buttonTargetSlug,image}) => {
    return (
    <div className={styles.cardContainer}>
      <Img className={styles.iconImage} fluid={image.fluid} alt={image?.title} />
      <h2 className={styles.heading}>{title}</h2>
      <div className={styles.description}>
        <RichText content={description} />
      </div>
      {buttonText && <a target={"_blank"} href={buttonTargetSlug}><button className={styles.button}>{buttonText}</button></a>}
    </div>
  )
}

export default SectionIconDescription
