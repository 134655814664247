// extracted by mini-css-extract-plugin
export var accordion = "accordion-module--accordion--36c7a";
export var accordionBlack = "accordion-module--accordion--black--a61cf";
export var accordionButton = "accordion-module--accordion-button--bd138";
export var accordionButtonIsDesktop = "accordion-module--accordion-button-is-desktop--540dd";
export var accordionButton__arrow = "accordion-module--accordion-button__arrow--6db0d";
export var accordionButton__arrowBlack = "accordion-module--accordion-button__arrow--black--b706b";
export var accordionButton__arrowIsDesktop = "accordion-module--accordion-button__arrow-is-desktop--e20fd";
export var accordionIsClose = "accordion-module--accordion--is-close--4df5f";
export var accordionIsDesktop = "accordion-module--accordion-is-desktop--f4ae2";
export var accordionIsOpen = "accordion-module--accordion--is-open--4de9f";
export var accordionOptions = "accordion-module--accordion-options--95268";
export var accordionOptionsIsDesktop = "accordion-module--accordion-options-is-desktop--e9780";