import React from "react"
import SectionIconDescription from "../section-icon-description/sectionIconDescription"
import * as styles from "./sectionContainer.module.scss"


const SectionContainer = ({
  tiles
}) => {
  return (
    <div className={styles.sectionContainer}>
        {tiles.map((tile)=>{
            return (
            <SectionIconDescription
            title={tile?.title}
            description={tile?.description}
            buttonText={tile?.buttonText}
            buttonTargetSlug={tile?.buttonTargetSlug}
            image={tile?.iconImage}
          />)
        })}
    </div>
  )
}

export default SectionContainer
