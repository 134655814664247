import React from "react";
import * as styles from './faq-content.module.scss';
import { RichText } from "../../Layout/RichText/RichText";
import Accordion from "../../Shared/Accordion/Accordion";
const FAQContent = ({
    heading,
    content
}: {
    heading: String,
    content: any
}) => {

    return (
        <div className={`styles.accordion`}>
            <Accordion arrowColor="black" isDesktop={true}>
            <h4>{heading}</h4>
                <div className={styles.richtext}>
                    {
                        content &&
                        <RichText content={content} />
                    }
                </div>
            </Accordion>
        </div>
    )
}

export default FAQContent