import React, { useEffect } from 'react'
import { useLocale, useSearchQuery } from '../../hooks'
import { zendeskChatDept, zendeskCookieLang } from '../../utils/mapping'
import { RichText } from '../Layout/RichText/RichText'
import * as styles from './zendesk-form.module.scss'

const ZendeskForm = () => {
    const locale = useLocale()
    const language = zendeskCookieLang[locale]

    const { contactUsContentPart1, contactUsContentPart2, contactUsButtonText } = useSearchQuery()

    const zendesk = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "ze-snippet")
        script.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=43d87919-0ce4-412c-9ef6-87d139bd92d8`)
        // script.setAttribute('async', 'true')
        script.onload = () => {
            if (locale === 'es-CL') zE('webWidget', 'open')
            zendeskLocale()
            if (locale === "en-US" || locale === "en-AU" || locale === "en-GB")
                zendeskChatForm()
        }
        document.body.appendChild(script)
    }

    const zendeskForm = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        let innerHTML = ''
        script.innerHTML = `
            window.zESettings = {
                webWidget: {
                    chat: { suppress: true },
                    helpCenter: { suppress: true },
                    talk: { suppress: true },
                    answerBot: { suppress: true },
                }
            };
            `
        document.body.appendChild(script)
    }

    const zendeskChatForm = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        script.innerHTML = `
            
            zE('webWidget:on', 'chat:departmentStatus', function(dept) {
                if (dept.name === "${zendeskChatDept[locale]}") {
                    if (dept.name === "${zendeskChatDept[locale]}" && dept.status === 'online') {
                        zE('webWidget', 'updateSettings', {
                        webWidget: {
                            chat: {
                                departments: {
                                    enabled: [''],
                                    select: "${zendeskChatDept[locale]}"
                                },
                                suppress: false
                            },
                            contactOptions: {
                                enabled: true,
                                contactButton: { '*': 'Get in touch' }
                              },
                        }
                        });
                    } else {
                        zE('webWidget', 'updateSettings', {
                        webWidget: {
                            chat: {
                                suppress: true
                            },
                            contactForm: { ticketForms: [{ id: 6292002231837, title: false }] } 
                        }
                        });
                    }
                }
            });

        `
        document.body.appendChild(script)
    }


    const zendeskLocale = () => {
        const script = document.createElement('script')
        script.setAttribute('id', "zendesk-script")
        script.setAttribute('type', "text/javascript")
        script.innerHTML = `
            zE('webWidget', 'setLocale', '${language}');
        `
        document.body.appendChild(script)

    }

    useEffect(() => {
        if (!document.getElementById('launcher')) {
            if (locale !== "en-US" && locale !== "en-AU" && locale !== "en-GB")
                zendeskForm()
            zendesk()
        } else
            zE('webWidget', 'show');

        return () => {
            zE('webWidget', 'hide');
        }

    }, [])

    useEffect(() => {
        if (!document.getElementById('ze-snippet')) {
            if (locale === 'es-CL') {
                zE('webWidget', 'open');
            }
        }
        return () => { zE('webWidget', 'close'); }
    }, [])


    const openPopup = () => {
        zE('webWidget', 'show');
        zE('webWidget', 'open');
    }

    return (contactUsContentPart1 || contactUsContentPart2) && contactUsButtonText ? (
        <section className={styles.container}>
            {contactUsContentPart1 ?
                <RichText content={contactUsContentPart1} />
                : ""}
            {contactUsButtonText ?
                <button className={styles.button} onClick={() => openPopup()}>{contactUsButtonText}</button>
                : ""}
            {contactUsContentPart2 ?
                <RichText content={contactUsContentPart2} />
                : ""
            }

        </section>
    ) : <></>
}

export default ZendeskForm