import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seoGlobal";
import { useLocale } from "../hooks";
import GenericHero from "../components/Shared/Generic/Hero/GenericHero";
import FAQComponent from "../components/FAQComponent/FAQComponent";
import FAQTitle from "../components/FAQComponent/FAQTitle/FAQTitle";
import ArticleProductSlider from "../ArticlePage/ArticleProductSlider/ArticleProductSlider";
import Schema from "../components/schema";
import { ContactForm } from "../components/ContactForm/ContactForm";
import SectionIconDescription from "../components/sections/section-icon-description/sectionIconDescription";
import SectionContainer from "../components/sections/section-container/sectionContainer";
import { zendeskGoLive } from "../utils/mapping";
import ZendeskForm from "../components/ZendeskForm/ZendeskForm";
import { getPathname } from "../utils/functions"

const PageHelpCenter = ({
    location,
    pageContext,
    data: {
        contentfulPageHelpCenter: {
            faQs,
            faqTitle,
            sections,
            seoDescription,
            seoTitle,
            slug,
            carelineScript,
            relatedProducts,
            noticePage,
            zendeskForn
        },
        allContentfulPageHelpCenter: {
            nodes: allLinks
        }
    }
}) => {
    const locale = useLocale()
    return (
        <>
            <Seo lang={locale} title={seoTitle} description={seoDescription?.seoDescription} hrefLangs={allLinks} />
            <Schema slug={getPathname(pageContext.node_locale,slug)}/>
            <div className="help-centre">
                {
                    sections.map((section, index) => {
                        if (section.__typename === 'ContentfulComponentHero') {
                            return (
                                <GenericHero
                                    subtitle={section?.subtitle?.subtitle}
                                    title={section?.title}
                                    key={index}
                                    description={section?.description?.description}
                                    fluid={section?.backgroundImage?.fluid}
                                    fluidMob={section?.backgroundImageMobile?.fluid}
                                    altText={section?.backgroundImage?.title}
                                    firstChild={index === 0}
                                />
                            )
                        } else if (section.__typename === "ContentfulComponentContent") {
                            return (
                                <FAQTitle key={index} data={section} noticePage={noticePage} />
                            )
                        } else if (section.__typename === "ContentfulComponentContainer") {
                            return (
                                <>
                                    <SectionContainer tiles={section.tiles} />
                                </>
                            )
                        }
                    })
                }
                {
                    (
                       !noticePage && zendeskGoLive[locale]
                            ? <ZendeskForm />
                            : ""
                    )
                }
                {/* <ContactForm carelineScript={carelineScript} /> */}
                <FAQComponent title={faqTitle} section={faQs} />
                
               {relatedProducts && <ArticleProductSlider productArray={relatedProducts} displayButton={true} extraClass={"helpCenter"} />
              }
            </div>
        </>
    )
}

export const PageHelpCenterQuery = graphql`
    query pageHelpCenter($id: String!, $contentful_id: String!){
        contentfulPageHelpCenter(id: { eq: $id }){
            slug
            carelineScript
            seoTitle
            noticePage
            seoDescription{
                seoDescription
            }
            sections{
                ... on ContentfulComponentHero {
                    __typename
                    id
                    name
                    title
                    description {
                        description
                    }
                    backgroundImage {
                        fluid (maxWidth: 2400, quality: 50) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    backgroundImageMobile {
                        title
                        fluid(maxWidth: 1023, quality: 50) {
                          ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                      }
                    type
                }
                  ... on ContentfulComponentContent {
                    __typename
                    id
                    title
                    heading
                    type
                    content {
                        raw
                    }
                }
                ... on ContentfulComponentContainer {
                    __typename
                    id
                    tiles {
                        ... on ContentfulComponentIconDescription{
                            buttonTargetSlug
                            buttonText
                            description {
                                raw
                            }
                            iconImage {
                                fluid {
                                    ...GatsbyContentfulFluid_withWebp_noBase64
                                }
                            }
                            title
                        }
                    }
                }   
            }
            faqTitle
            faQs {
                title
                heading
                content {
                    raw
                }
            }
            relatedProducts {
                slug
                title
                thumbnailImage {
                    fluid {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
        }
        allContentfulPageHelpCenter(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: {ne: null}}){
            nodes {
                slug
                node_locale
            }
        }
    }
`

export default PageHelpCenter